import React from 'react'
import { graphql } from 'gatsby'
import Issue from '../components/Issue'

export default function Template({ data }) {
  const {
    allPrismicIssue: {
      edges: [
        {
          node: { data: issue },
        },
      ],
    },
  } = data
  return <Issue issue={issue} />
}

export const pageQuery = graphql`
  query prismicIssue($uid: String!) {
    allPrismicIssue(filter: { uid: { eq: $uid } } ) {
      edges {
        node {
          id
          uid
          data {
            issue_title {
              text
              html
            }
            issue_cover_image {
              alt
              copyright
              url
            }
            issue_publication_date
            issue_description {
              html
            }
            issue_articles {
              article {
                uid
                document {
                  data {
                    article_title {
                      text
                    }
                    article_description {
                      html
                    }
                    article_pdf {
                      url
                    }
                  }
                }
              }
            }
            issue_artists_description {
              html
            }
            issue_media_information {
              html
            }
          }
        }
      }
    }
  }
`
